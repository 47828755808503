import { useEffect } from 'react'

export const useStoreClickIds = () => {
  useEffect(() => {
    if (
      typeof window !== 'undefined' &&
      window?.location &&
      window?.location?.search
    ) {
      const params = new URLSearchParams(window.location.search)

      const googleClickId = params.get('gclid')
      const facebookClickId = params.get('fbclid')

      window.localStorage.setItem('gclid', googleClickId ?? '')
      window.localStorage.setItem('fbclid', facebookClickId ?? '')
    }
  }, [])
}
