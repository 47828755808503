/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState, useMemo, memo } from "react";
import cn from "classnames";
import { InView } from "react-intersection-observer";
import OutsideClickHandler from "react-outside-click-handler";
import { useRouter } from "next/router";

// Components
import QueryOptions from "./QueryOptions";
import Button from "../newButton/Button";
// Utils
import styles from "./Bar.module.scss";
import { convertCategory } from "./lib/utils";
import { useDiscountContext } from "../../context/discountContext";
import query from "@/utils/query";
import { SEARCH_DATA } from "@/graphql/index";

const Bar = ({
  selectedBreed,
  setSelectedBreed,
  selectedCategory,
  selectedZipcode,
  setNewRoute,
  setShowNotificationForm,
  scrollRef,
  pinSearchBar,
  isTablet,
  // Only needed for mobile
  isMobile,
  isDiscountBannerClosed,
  isHome,
}) => {
  const [zipcode, setZipcode] = useState(selectedZipcode);
  const [filter, setFilter] = useState("");
  const [categories, setCategories] = useState({});
  const [breeds, setBreeds] = useState([]);
  const [filteredBreeds, setFilteredBreeds] = useState([]);
  const [loadingDogData, setLoadingDogData] = useState(true);
  const [showError, setShowError] = useState(false);
  const [showOptions, setShowOptions] = useState(false);
  const [hide, setHide] = useState(false);
  const router = useRouter();

  // hasBanner
  const discountBannerCondition = ["/search", "/puppy"];
  const { discount, endDate, now } = useDiscountContext();
  const hasBanner = useMemo(
    () =>
      discountBannerCondition.some(
        (cond) => router.pathname.includes(cond) || router.pathname === "/"
      ) &&
      !(now > endDate) &&
      !isDiscountBannerClosed &&
      !!discount?.amount &&
      discount?.bannerText !== "<p><br></p>" &&
      discount?.bannerText !== null &&
      !discount?.customerSpecific,
    [discountBannerCondition, router.pathname, isDiscountBannerClosed, discount]
  );

  useEffect(() => {
    setZipcode(selectedZipcode);
  }, [selectedZipcode]);
  useEffect(() => {
    setFilter(selectedBreed?.name);
  }, [selectedBreed]);

  useEffect(() => {
    if (!filter) setFilter("");

    if (filter?.length) {
      setFilteredBreeds(
        breeds
          ?.filter((b) => b.name?.toLowerCase().includes(filter.toLowerCase()))
          .sort((a, b) => (a.count > b.count ? -1 : 1))
          .slice(0, 8) || []
      );
    } else {
      setFilteredBreeds(breeds.slice(0, 8));
    }
  }, [filter, breeds]);

  useEffect(() => {
    const fetchDataForSearch = async () => {
      try {
        const { categories, dogsCountByBreed } = await query({
          query: SEARCH_DATA,
        });
        setCategories(categories);
        setFilteredBreeds(dogsCountByBreed.slice(0, 8));
        setBreeds(dogsCountByBreed);
        setLoadingDogData(false);
      } catch (e) {
        console.error("SEARCH DATA ERROR on BAR", e);
      }
    };

    setTimeout(fetchDataForSearch, 100);
  }, []);

  function handleShowError() {
    setShowError(true);
    setTimeout(() => {
      setShowError(false);
    }, 4000);
  }
  return (
    <>
      <InView
        onChange={(b) => {
          if (pinSearchBar !== undefined) {
            !pinSearchBar && !b ? setHide(true) : setHide(false);
          }
        }}
        className={cn({
          [styles.bar]: true,
          [styles["home-pinned-with-banner"]]: pinSearchBar && hasBanner,
          [styles["home-pinned"]]: pinSearchBar && !hasBanner,
          [styles["home-unpinned"]]: pinSearchBar === false,
          [styles.hidden]: hide,
          [styles.isBannerOpen]: hasBanner,
        })}
      >
        <div className={styles.formsContainer}>
          <div
            className={styles.search}
            onClick={() => {
              if (!isMobile && scrollRef && pinSearchBar === false) {
                window.scrollTo({
                  top: scrollRef.current.offsetTop - 90,
                  behavior: "smooth",
                });
              }
            }}
          >
            <div
              className={styles.inputContainer}
              onClick={() => {
                setShowOptions(!showOptions);
              }}
            >
              <div
                className={styles.label}
                onClick={() => {
                  setShowOptions(!showOptions);
                }}
              >
                Search
              </div>

              {!isMobile ? (
                <input
                  id="query"
                  type="text"
                  placeholder="Breed or puppy name"
                  autoComplete="off"
                  value={filter}
                  onChange={(e) => setFilter(e.target.value)}
                  disabled={loadingDogData}
                />
              ) : (
                <div className={styles.empty}>
                  {filter || "Breed or puppy name"}
                </div>
              )}
            </div>

            {/* CLEAR INPUT */}
            {!(
              !filter ||
              filter === selectedBreed?.name ||
              filter === convertCategory(selectedCategory)
            ) && (
              <div className={styles.clear} onClick={() => setFilter("")}>
                <img src="/close-modal-grey.svg" alt="close icon" />
              </div>
            )}

            {/* CLEAR STATE */}
            {filter &&
              (filter === selectedBreed?.name ||
                filter === convertCategory(selectedCategory)) && (
                <div
                  className={styles.clear}
                  onClick={() => {
                    if (selectedBreed?.name) {
                      setSelectedBreed({
                        id: undefined,
                        name: undefined,
                      });
                      setFilter("");
                    }
                    if (selectedCategory) setNewRoute("category", null);
                  }}
                >
                  <img src="/close-modal-grey.svg" alt="close icon" />
                </div>
              )}

            {showOptions && (
              <QueryOptions
                selectedBreed={selectedBreed}
                categories={categories}
                filteredBreeds={filteredBreeds}
                setShowOptions={setShowOptions}
                showOptions={showOptions}
                setNewRoute={setNewRoute}
                setShowNotificationForm={setShowNotificationForm}
                // Only needed for mobile
                isMobile={isMobile}
                query={filter}
                setQuery={setFilter}
                loading={loadingDogData}
                hasBanner={hasBanner}
              />
            )}
          </div>

          <form
            autoComplete="off"
            className={styles.zipcode}
            onSubmit={(e) => {
              e.preventDefault();
              setNewRoute("zipcode", zipcode, "sort", "closest");
            }}
          >
            <OutsideClickHandler
              onOutsideClick={() => {
                if (selectedZipcode && selectedZipcode !== zipcode)
                  setZipcode(selectedZipcode);
              }}
            >
              <div className={styles.inputContainer}>
                <label htmlFor="zipcode">Near</label>
                <input
                  id="zipcode"
                  type="text"
                  placeholder="ZIP or Postal"
                  value={zipcode}
                  onChange={(e) => {
                    setZipcode(e.target.value);
                  }}
                  onFocus={() => {
                    if (selectedZipcode) setZipcode("");
                  }}
                />
              </div>

              {zipcode && selectedZipcode === zipcode && (
                <div
                  className={styles.clear}
                  onClick={() => setNewRoute("zipcode", null, "sort", null)}
                >
                  <img src="/close-modal-grey.svg" alt="search" />
                </div>
              )}

              <Button
                centerImage="/search/search.svg"
                theme={[
                  "pink-solid",
                  pinSearchBar === false ? "medium" : "search-bar",
                ]}
                height={isTablet ? 20 : 25}
                width={isTablet ? 20 : 25}
                onClick={async () => {
                  const zipcodes = (await import("zipcodes")).default;
                  return zipcode
                    ? zipcodes.lookup(zipcode)
                      ? setNewRoute("zipcode", zipcode, "sort", "closest")
                      : handleShowError()
                    : setNewRoute("");
                }}
                altImg="Search icon"
              />
            </OutsideClickHandler>
          </form>
        </div>
      </InView>

      {showError && (
        <div
          onClick={() => setShowError(false)}
          className={cn({
            [styles.errorZipcode]: !isHome,
            [styles.errorZipCodeHome]: isHome,
            [styles.errorZipCodeHomePin]: pinSearchBar,
          })}
        >
          {" "}
          <img src="/search/alert-circle.svg" alt="alert circle" />
          Zip / Postal Code not valid
        </div>
      )}

      {showOptions && <div className={styles.background} />}
    </>
  );
};

export default memo(Bar);
